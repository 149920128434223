import { Grid } from "@mui/material";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import GoToTop from "../../GoToTop";
import styles from "../../styles/Enterprise.module.css";
import { FormattedMessage } from "react-intl";

export default function Gaming() {
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const detailRef1 = useRef(null);

  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    const element1 = detailRef1.current;
    gsap.fromTo(
      element1,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
  }, []);

  return (
    <div className={styles.gaming}>
      <GoToTop />
      <Grid container className={styles.ElementEnterpriseFirst} ref={detailRef}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          style={{ paddingTop: "28px" }}>
          <div className={styles["enterprise-block"]}>
            <div>
              <h3 className={styles["enterprise-h3"]}>
                <FormattedMessage id="gaming_market_h1"/>
              </h3>
            </div>
          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/images/blogdrill/Gaming/Gaming1.svg"
            }
            className={styles["enterprise-img"]}
            alt='enterprise'
          />
        </Grid>
      </Grid>

      <div className={styles.enterpriseMain} ref={detailRef1}>
        <Grid container className={styles.ElementEnterprises}>
          <Grid item></Grid>
          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}>
                <FormattedMessage id="gaming_market_h2"/>
              </h3>

              <p style={{ fontSize: "19px" }}>
                <FormattedMessage id="gaming_p1"/>
              </p>

              <h3 className={styles.PHeading}><FormattedMessage id="gaming_metaverse_h"/></h3>

              <p style={{ fontSize: "19px" }}>
                <FormattedMessage id="gaming_metaverse_p1"/>
              </p>

              <p style={{ fontSize: "19px" }}>
                <FormattedMessage id="gaming_metaverse_p2"/>
              </p>
            </div>
          </Grid>
        </Grid>
      
      </div>
    </div>
  );
}
