import React from "react";
import styles from "../../styles/Partner.module.css";
import { Grid } from "@mui/material";
import GoToTop from "../../GoToTop";

const YourGrantAgency = () => {
  const goTocomp = () => {
    return window.open("https://www.yourgrantagency.co.uk/", "__blank");
  };
  return (
    <div className={styles.main}>
      <GoToTop />

      <Grid container className={styles.ElementEnterpriseFirst}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          style={{ paddingTop: "42px" }}>
          <div className={styles["enterprise-block"]}>
            <h1 className={styles["enterprise-h1"]}>YourGrant Agency</h1>
            <div>
              <h3 className={styles["enterprise-h3"]}></h3>
            </div>
          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/images/YourGrantAgency/yourGrantAgencyPage.svg"
            }
            className={styles["enterprise-img"]}
            alt='partner'
          />
          <div className={styles.PHeading}>
            <h3>
            At YourGrant Agency, we believe that every business deserves the 
            opportunity to discover and access financial support that can drive 
            growth and positive change. 
            </h3>

            <p>
            We're dedicated to helping small and medium-sized enterprises (SMEs) discover and access the right 
            grants to drive their growth and success. Securing financial support for your business can be complex 
            and time-consuming. 
            </p>

            <p>
            The agency specializes in identifying and matching SMEs with a variety of government grant 
            opportunities across different sectors in the United Kingdom. We stay updated with the latest 
            business funding programs from government bodies, ensuring our clients have access to the most 
            relevant opportunities for their specific needs.
            </p>
          </div>
        </Grid>
      </Grid>
      <div className={styles.buttonPartner}>
        <button className={styles["btn-view"]} onClick={goTocomp}>
          View More
        </button>
      </div>
    </div>
  );
};

export default YourGrantAgency;
