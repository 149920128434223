import { Grid } from "@mui/material";
import React from "react";
import { certification_imgs } from "../../../data";
import GoToTop from "../../GoToTop";
import styles from "../../styles/Certifications.module.css";
import { FormattedMessage } from "react-intl";

export default function Certification() {
  return (
    <div className={styles["certification_container"]}>
      <GoToTop />
      <div className={styles["certification"]}>
        <div>
          <div className={styles["certification-title"]}>
            <h3 className={styles["certification-h3"]}>
              <FormattedMessage id="certifications_h"/>
            </h3>
            <p className={styles["certification-p"]}>
              <FormattedMessage id="certifications_p"/>
            </p>
          </div>

          <div className={styles["certification-img"]}>
            <p className={styles["certification-p1"]}><FormattedMessage id="certifications_p1"/></p>
            <Grid
              id='Certification-part'
              container
              spacing={2}
              className={styles["certification-part"]}>
              {certification_imgs.map((item, index) => {
                return (
                  <Grid
                    key={index}
                    className={styles["certification-imgs"]}
                  
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}>
                    <div className=''>
                      <div className={styles["img-hover-zoom"]}>
                        <img
                          className={styles["certification-imghover"]}
                          id='imghover'
                          src={process.env.PUBLIC_URL + item.img}
                          alt={item.title}
                          loading='lazy'
                        />
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>

          <div className={styles["membership-images"]}>
            <p className={styles["membership-title"]}>
              <FormattedMessage id="certifications_p2"/>
            </p>
            <div className={styles["membership-div"]}>
              <Grid className={styles.certificate}>
                <Grid>
                  {" "}
                  <div className={styles["main-img"]} >
                  <div className={styles["img-hover-zoom"]} >
                    <img
                      className={styles.memberimg}
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/certification/ma1.svg"
                      }
                      alt='certification'
                      id='imghover'
                    />
                  </div>
                  </div>
                </Grid>
                <Grid className={styles.member}>
                  <Grid>
                    <div  className={styles["main-img"]}>
                    <div className={styles["img-hover-zoom"]}>
                      <img
                        className={styles.memberimg}
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/certification/ma2.svg"
                        }
                        alt='certification'
                        id='imghover'
                      />
                    </div>
                    </div>
                  </Grid>
                  <Grid>
                    {" "}
                    <div  className={styles["main-img"]}>
                    <div className={styles["img-hover-zoom"]}>
                      <img
                        className={styles.memberimg}
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/certification/ma3.svg"
                        }
                        alt='certification'
                        id='imghover'
                      />
                    </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
}
