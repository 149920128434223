import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import styles from './Search.module.css'
const Search =(props) => {

    return (
    <div className={styles.searchmain}>
    <input
    id={props.id}
    type={props.type}
    className={`${styles.inputsearch} ${styles.search} ${props.className}`}
    placeholder={props.placeholder}
    tabIndex={props.tabIndex}
    onChange={props.onChange}
    value={props.value}

    autoComplete='off'
  />
{props.closeBtn ?
     <Link to={props.path} ><span className={styles.CloseButton}><CloseIcon onClick={props.onClick} style={{fontSize:'1.5em', color:'black', marginLeft:'-25px'}}/></span></Link>
 : '' }

    </div>) 
}

export default Search