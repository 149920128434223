import React from "react";
import GoToTop from "../../GoToTop";
import styles from "../../styles/Policy.module.css";
import { FormattedMessage } from "react-intl";

export default function Policy() {
  return (
    <div className={styles.policy_container}>
      <GoToTop />
      <div className={styles.policy}>
        <div>
          <div className={styles["policy-title"]}>
            <h2 className={styles.policyHeading}><FormattedMessage id="privacy_policy_h"/></h2>
            <p className={styles["policy-title-p"]}>
              <FormattedMessage id="privacy_policy_p"/>
            </p>
            <br />
 
            <p className={styles["policy-p"]}>
              <FormattedMessage id="privacy_policy_achive_p"/>
            </p>
            <br />
            <ul className={styles["list-ul"]}>
              <li><FormattedMessage id="privacy_policy_li"/></li>
              <li><FormattedMessage id="privacy_policy_li1"/></li>
              <li><FormattedMessage id="privacy_policy_li2"/></li>
              <li><FormattedMessage id="privacy_policy_li3"/></li>
              <li>
              <FormattedMessage id="privacy_policy_li4"/>

              </li>
              <li><FormattedMessage id="privacy_policy_li5"/></li>
              <li><FormattedMessage id="privacy_policy_li6"/> </li>
            </ul>

           
            <p className={styles["policy-p"]}>
            <FormattedMessage id="privacy_policy_li7"/>
            </p>
            
          </div>
        </div>
      </div>
    </div>
  );
}
