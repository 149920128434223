import React, { useRef, useEffect } from "react";
import styles from "../../styles/Testimonials.module.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import GoToTop from "../../GoToTop";

import { Grid } from "@mui/material";
import { TweenMax } from "gsap/gsap-core";
import { FormattedMessage } from "react-intl";

export default function Testimonials() {
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);

  revealsFirstRef.current = [];

  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );

    revealsFirstRef.current.forEach((el, index) => {
      TweenMax.fromTo(
        el,
        1.5,
        {
          width: "80%",
          marginLeft: "10%",
          marginRight: "10%",
          delay: 0,

        },
        {
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          delay: 0,
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top bottom",
            toggleActions: "play none none ",

          },
        }
      );
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };

  return (
    <div style={{ marginBottom: "98px" }} className={styles.testimonialsmain}>
      <GoToTop />
      <div className={styles.stratHead}>
        <div ref={detailRef} className={styles.test}>
          <h4>
            <FormattedMessage id="heading_testimonials" />
          </h4>
          <h1>
            <FormattedMessage id="building_rel_testimonials" />
          </h1>
          <p style={{ fontSize: "22px" }}>
            {" "}
            <FormattedMessage id="read_here_testimonials" />
          </p>
        </div>
      </div>

      <div className={styles.stretVedio} ref={addToFirstRef}>
        <img
          width='100%'
          src={process.env.PUBLIC_URL + "/assets/images/testimonials/testimonials.svg"}
          alt='Testimonial'
        />
      </div>
      <div className={`${styles.Insights} ${styles.test}`} ref={detailRef}>
        <p>
          <FormattedMessage id="when_we_testimonials" />
        </p>
      </div>

      <div className={styles.twoImgTextGridMain}>
        <Grid className={styles.imgText} ref={detailRef}>
          <div style={{ width: "90%" }} className={styles.test}>
          <img
              width='100%'
              height='100%'
              src={
                process.env.PUBLIC_URL + "/assets/images/testimonials/jeremy.svg"
              }
              alt='Experiment'
            />
          </div>
          <div style={{ width: "90%" }} className={styles.test}>
            <h3>
              <q>
                <FormattedMessage id="data_temp_testimonials" />
              </q>
            </h3>
            <p className={styles.textp}>Jeremy Foster, Calque Inc. Austin</p>
          </div>
        </Grid>
      </div>
      <div className={styles.twoImgTextGridMain}>
        <Grid className={styles.imgText} ref={detailRef}>
          <div style={{ width: "90%" }} className={styles.test}>
            <h3>
              <q>
                <FormattedMessage id="data_templ_testimonials" />
              </q>
            </h3>
            <p className={styles.textp}>
              Abi Baldridge, Director of Research, The Feinberg School of
              Medicine,Chicago
            </p>
          </div>
          <div style={{ width: "90%" }} className={styles.test}>
          <img
              width='100%'
              height='100%'
              src={
                process.env.PUBLIC_URL + "/assets/images/testimonials/abibaldridge.svg"
              }
              alt='Experiment'
            />
          </div>
        </Grid>
      </div>

      <div className={styles.twoImgTextGridMain}>
        <Grid className={styles.imgTexts} ref={detailRef}>
          <div style={{ width: "90%" }} className={styles.tests}>
            <img
               width='100%'
               height='350px'
              src={
                process.env.PUBLIC_URL + "/assets/images/testimonials/dori1.svg"
              }
              alt='Experiment'
            />
          </div>
          <div style={{ width: "90%" }} className={styles.tests}>
            <img
               width='100%'
               height='350px'
              src={
                process.env.PUBLIC_URL + "/assets/images/testimonials/dori2.svg"
              }
              alt='Experiment'
            />
          </div>
        </Grid>
      </div>

      <div className={`${styles.Insights} ${styles.test}`} ref={detailRef}>
        <h3 className={styles.texth3}>
          <q>
            <FormattedMessage id="data_templa_testimonials" />
          </q>
        </h3>
        <p className={styles.texth3}>
          Dori Daganhardt, Senior Leader, Product Management, CoreLogic, Los
          Angeles
        </p>
      </div>

      <div className={styles.twoImgTextGridMain}>
        <Grid className={styles.imgText} ref={detailRef}>
          <div style={{ width: "90%" }}>
            <img
              width='100%'
              height='100%'
              src={
                process.env.PUBLIC_URL + "/assets/images/testimonials/pawan.svg"
              }
              alt='Experiment'
            />
          </div>
          <div style={{ width: "90%" }} className={styles.test}>
            <h3>
              <q>
                <FormattedMessage id="i_have_testimonials" />{" "}
              </q>
            </h3>
            <p className={styles.textp}>Pawan Uberoy, CEO, Epiphani Inc, San Jose</p>
          </div>
        </Grid>
      </div>

      <div className={styles.twoImgTextGridMain}>
        <Grid className={styles.imgText} ref={detailRef}>
          <div style={{ width: "90%" }} className={styles.test}>
            <h3>
              <q>
                <FormattedMessage id="data_templat_testimonials" />
              </q>
            </h3>
            <p className={styles.textp}>Kamel Boulos, Chief Technology Officer, Stonghome.com, Irvine</p>
          </div>
          <div style={{ width: "90%" }}>
            <img
              width='100%'
              height='100%'
              src={
                process.env.PUBLIC_URL + "/assets/images/testimonials/kamel.svg"
              }
              alt='Experiment'
            />
          </div>
        </Grid>
      </div>

      <div className={styles.stretVedios} ref={detailRef}>
        <img
          width='90%'
          src={process.env.PUBLIC_URL + "/assets/images/testimonials/fabio.svg"}
          alt='Testimonial'
          className={styles.testimg}
        />
      </div>

      <div className={`${styles.Insights} ${styles.test}`} ref={detailRef}>
        <h3 className={styles.texth3}>
          <q>
            <FormattedMessage id="id_like_testimonials" />
          </q>
        </h3>
        <p className={styles.texth3}>Fabio Carvelli, Product Manager, EMEA, Lombardy</p>
      </div>
      <div className={styles.twoImgTextGridMain}>
        <Grid className={styles.imgText} ref={detailRef}>
          <div style={{ width: "90%" }}>
            <img
              width='100%'
              height='100%'
              src={
                process.env.PUBLIC_URL + "/assets/images/testimonials/sonamahtani.svg"
              }
              alt='Experiment'
            />
          </div>
          <div style={{ width: "90%" }} className={styles.test}>
            <h3>
              <q>
                <FormattedMessage id="data_template_testimonials" />
              </q>
            </h3>
            <p className={styles.textp}>Sona Mahtani, Chief Executive, Selby Trust, London</p>
          </div>
        </Grid>
      </div>

      <div className={styles.twoImgTextGridMain}>
        <Grid className={styles.imgText} ref={detailRef}>
          <div style={{ width: "90%" }} className={styles.test}>
            <h3>
              <q>
                <FormattedMessage id="i_had_testimonials" />
              </q>
            </h3>
            <p className={styles.textp}>
              Dr. Ajay S Vamadevan, Deputy Director & Head, Health Systems Uni,
              New Delhi
            </p>
          </div>
          <div style={{ width: "90%" }}>
            <img
              width='100%'
              height='100%'
              src={
                process.env.PUBLIC_URL + "/assets/images/testimonials/ajay.svg"
              }
              alt='Experiment'
            />
          </div>
        </Grid>
      </div>
      <div className={styles.twoImgTextGridMain}>
        <Grid className={styles.imgText} ref={detailRef}>
          <div style={{ width: "90%" }}>
            <img
              width='100%'
              height='100%'
              src={
                process.env.PUBLIC_URL + "/assets/images/testimonials/siddharth.svg"
              }
              alt='Experiment'
            />
          </div>
          <div style={{ width: "90%" }} className={styles.test}>
            <h3>
              <q>
                <FormattedMessage id="Siddharth_q_testimonials" />
              </q>
            </h3>
            <p className={styles.textp}>Siddharth Vajirkar, Director of Software, Securiti, San Jose</p>
</div>
        </Grid>
      </div>

      <div className={styles.twoImgTextGridMain}>
        <Grid className={styles.imgText} ref={detailRef}>
          <div style={{ width: "90%" }} className={styles.test}>
            <h3>
              <q>
                <FormattedMessage id="nanda_q_testimonials" />
              </q>
            </h3>
            <p className={styles.textp}>Nanda Kumar, CEO, Vendor Globe, Bengaluru</p>
          </div>
          <div style={{ width: "90%" }}>
            <img
              width='100%'
              height='100%'
              src={
                process.env.PUBLIC_URL +
                "/assets/images/testimonials/nandakumar.svg"
              }
              alt='Experiment'
            />
          </div>
        </Grid>
      </div>

      <div className={styles.stretVedios} ref={detailRef}>
        <img
          width='90%'
          src={process.env.PUBLIC_URL + "/assets/images/testimonials/traci.svg"}
          alt='Testimonial'
          className={styles.testimg}
        />
      </div>
      <div className={`${styles.Insights} ${styles.test}`} ref={detailRef}>
      <p className={styles.texth3}>Traci Morrison, PMO Director, Sunflower Bank, Michigan</p>
      </div>
      <div className={styles.twoImgTextGridMain}>
        <Grid className={styles.imgText} ref={detailRef}>
          <div style={{ width: "90%" }}>
            <img
              width='100%'
              height='100%'
              src={
                process.env.PUBLIC_URL + "/assets/images/testimonials/deo.svg"
              }
              alt='Experiment'
            />
          </div>
          <div style={{ width: "90%" }} className={styles.test}>
            <h3>
              <q>
                <FormattedMessage id="deo_q_testimonials" />
              </q>
            </h3>
            <p className={styles.textp}>
              Deo Massawe , Chief Technology Officer at Bank of Kigali TecHouse,
              Kigali, Rwanda
            </p>
          </div>
        </Grid>
      </div>

      <div className={styles.twoImgTextGridMain}>
        <Grid className={styles.imgText} ref={detailRef}>
          <div style={{ width: "90%" }} className={styles.test}>
            <h3>
              <q>
                <FormattedMessage id="ares_q_testimonials" />
              </q>
            </h3>
            <p className={styles.textp}>
              Ares Abdulrazaq, Packet Network Manager at Fanoos Telecom, Iraq
            </p>
          </div>
          <div style={{ width: "90%" }}>
            <img
              width='100%'
              height='100%'
              src={
                process.env.PUBLIC_URL + "/assets/images/testimonials/ares.svg"
              }
              alt='Experiment"\'
            />
          </div>
        </Grid>
      </div>

      <div className={`${styles.stretVedios} ${styles.testimage}`} ref={detailRef}>
        <img
          width='90%'
          src={
            process.env.PUBLIC_URL + "/assets/images/testimonials/darmen.svg"
          }
          alt='Testimonial'
          className={styles.testimg}
        />
      </div>
      <div className={`${styles.Insights} ${styles.test}`} ref={detailRef}>
        <h3 className={styles.texth3}>
          <q>
            <FormattedMessage id="darmen_q_testimonials" />
          </q>
        </h3>
        <p className={styles.texth3}>Darmen Amanbayev, Lafa Network, Astana, Kazakhstan</p>
      </div>

      <div className={styles.twoImgTextGridMain}>
        <Grid className={styles.imgText} ref={detailRef}>
          <div style={{ width: "90%" }} className={styles.test}>
            <h3>
              <q>
                <FormattedMessage id="barbara_q_testimonials" />
              </q>
            </h3>
            <p className={styles.textp}>Barbara O'Connell, Chief Executive Officer, Enovasis, Detroit</p>
          </div>
          <div style={{ width: "90%" }}>
            <img
              width='100%'
              height='100%'
              src={
                process.env.PUBLIC_URL +
                "/assets/images/testimonials/barbara.svg"
              }
              alt='Experiment'
            />
          </div>
        </Grid>
      </div>

      <div className={styles.twoImgTextGridMain}>
        <Grid className={styles.imgText} ref={detailRef}>
          <div style={{ width: "90%" }}>
            <img
              width='100%'
              height='100%'
              src={
                process.env.PUBLIC_URL + "/assets/images/testimonials/brian.svg"
              }
              alt='Experiment'
            />
          </div>
          <div style={{ width: "90%" }} className={styles.test}>
            <h3>
              <q>
                <FormattedMessage id="brian_q_testimonials" />
              </q>
            </h3>
            <p className={styles.textp}>
              Brian Nkuruh, Country Director, Cheza Rwanda Games, Kigali, Rwanda
            </p>
          </div>
        </Grid>
      </div>

      <div className={styles.twoImgTextGridMain}>
      <Grid className={`${styles.imgTexts} ${styles.testimages}`} ref={detailRef}>
          <div style={{ width: "90%" }} className={styles.tests}>
            <img
              width='100%'
              height='350px'
              src={
                process.env.PUBLIC_URL +
                "/assets/images/testimonials/sharada1.svg"
              }
              alt='Experiment'
            />
          </div>
          <div style={{ width: "90%" }} className={styles.tests}>
            <img
              width='100%'
              height='350px'
              src={
                process.env.PUBLIC_URL +
                "/assets/images/testimonials/sharada2.svg"
              }
              alt='Experiment'
            />
          </div>
        </Grid>
      </div>

      <div className={`${styles.Insights} ${styles.test}`} ref={detailRef}>
        <h3 className={styles.texth3}>
          <q>
            <FormattedMessage id="sharada_q_testimonials" />
          </q>
        </h3>
        <p className={styles.texth3}>Sharada Iyer, CEO, Souqtherapy, Oman</p>
      </div>
    </div>
  );
}
