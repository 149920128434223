import React from "react";
import styles from "../../styles/Partner.module.css";
import { Grid } from "@mui/material";
import GoToTop from "../../GoToTop";

const ClinAlly = () => {
  const goTocomp = () => {
    return window.open("https://www.clinally.com/", "__blank");
  };
  return (
    <div className={styles.main}>
      <GoToTop />

      <Grid container className={styles.ElementEnterpriseFirst}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          style={{ paddingTop: "42px" }}>
          <div className={styles["enterprise-block"]}>
            <h1 className={styles["enterprise-h1"]}>ClinAlly</h1>
            <div>
              <h3 className={styles["enterprise-h3"]}></h3>
            </div>
          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
          <img
            src={
              process.env.PUBLIC_URL + "/assets/images/clinally/clinally.svg"
            }
            className={styles["enterprise-img"]}
            alt='partner'
          />
          <div className={styles.PHeading}>
            <h3>
              We believe that every human being on this planet deserves access
              to quality healthcare and it can be achieved if we (healthcare
              providers, patients, and technocrats) build together solutions
              which are affordable, sustainable, user-friendly, and most
              importantly-designed to meet the actual needs of the society.
            </h3>

            <p>
              Health system worldwide is facing challenges of chronic diseases
              and lack of expertise to tackle the growing burden of morbidity
              and mortality related to chronic diseases. Despite recognition of
              the benefits associated with early detection and treating chronic
              diseases and their risk factors, control rates remain suboptimal.{" "}
            </p>

            <p>
              The researchers from prestigious institutions together developed
              several digital technologies and interventions for standardizing,
              integrating, and improving quality and access to chronic disease
              care in healthcare facilities. The knowledge and experience from
              these research projects have led to the development of the
              ClinAlly – a platform – Of the Doctor, By the Doctor, For the
              Doctor.
            </p>
          </div>
        </Grid>
      </Grid>
      <div className={styles.buttonPartner}>
        <button className={styles["btn-view"]} onClick={goTocomp}>
          View More
        </button>
      </div>
    </div>
  );
};

export default ClinAlly;
