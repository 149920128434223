import React from "react";
import styles from "../../styles/Partner.module.css";
import { Grid } from "@mui/material";
const Ozone = () => {
  const goTocomp = () => {
    return window.open("/assets/images/Ozone_Brochure.pdf", "__blank");
  };
  return (
    <div className={styles.main}>
      <Grid container className={styles.ElementEnterpriseFirst}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          style={{ paddingTop: "42px" }}>
          <div className={styles["enterprise-block"]}>
            <h1 className={styles["enterprise-h1"]}>Ozone</h1>
            <div>
              <h3 className={styles["enterprise-h3"]}></h3>
            </div>
          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
          <img
            src={process.env.PUBLIC_URL + "/assets/images/ozone/ozone.svg"}
            className={styles["enterprise-img"]}
            alt='ozone'
          />
          <div className={styles.PHeading}>
            <h3>
              Ozone is next generation ERP due to the fact that it is platform
              independent and makes itself available in IoS/Android platforms
              thereby driving mobility. Decision makers doesn't have to be on
              site to keep the business moving.
            </h3>

            <p>
              Modern day manufacturing sector demands highly complicated data
              integration combined with very less manual dependency for their
              business activities. From estimating to costing, from order intake
              to delivery, each and every process in the industry needs close
              monitoring to identify areas of cost reduction and increased
              productivity. Ozone is developed exactly taking these requirements
              in to consideration and can provide dynamic solutions to any kind
              of business requirements. In addition to its role as an ERP
              solution provider, it provides process knowledge and best practice
              tested and proven by the best business groups in the industry.
              ERP's have grown from its stature of just - providing business
              with data for decision making - to a very important tool for
              implementing standard operating procedures and identifying
              bottlenecks in achieving optimum capacity. Ozone does exactly this
              and has the advantage of having versatility and flexibility to
              meet any type of customized requirements of its clients.
            </p>

            <p>
              Analytics have never been so powerful and comprehensive before
              with the D3 charts in Ozone providing multi dimensional Business
              Intelligence. Ozone have set of powerful modules starting from
              Order Management to Shipping. Ozone has done extensive study of
              each department in manufacturing sector and has gone through the
              minutest details to ensure that users feel comfortable and with
              its interactive search options, ensures data retrieval with
              effortless ease.
            </p>
          </div>
        </Grid>
      </Grid>
      <div className={styles.buttonPartner}>
        <button className={styles["btn-view"]} onClick={goTocomp}>
          View More
        </button>
      </div>
    </div>
  );
};

export default Ozone;
