import React from "react";
import styles from "../../styles/Partner.module.css";
import { Grid } from "@mui/material";
import GoToTop from "../../GoToTop";

const InterSystems = () => {
  const goTocomp = () => {
    return window.open("https://www.intersystems.com/sa/", "__blank");
  };
  return (
    <div className={styles.main}>
      <GoToTop />

      <Grid container className={styles.ElementEnterpriseFirst}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          style={{ paddingTop: "42px" }}>
          <div className={styles["enterprise-block"]}>
            <h1 className={styles["enterprise-h1"]}>InterSystems</h1>
            <div>
              <h3 className={styles["enterprise-h3"]}></h3>
            </div>
          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/images/intersystems/InterSystems.svg"
            }
            className={styles["enterprise-img"]}
            alt='partner'
          />
          <div className={styles.PHeading}>
            <h3>Better data, better decisions, better outcomes.</h3>

            <p>
              InterSystems is founded on two core principles: excellence and
              customer success. As the leading provider of data solutions for
              industries with critical needs like healthcare, financial
              services, and logistics, we realize that lives and livelihoods
              rely on our technology.That’s why we put everything we have into
              creating robust technology that can meet today’s toughest demands.
              No matter what the data challenge, we will build a solution.
              Because solving problems is what we do.
            </p>

            <p>
              Every product we design is in the service of our customers. We
              recognize the effect our technology has on their organizations,
              which is why we go above and beyond to support our customers and
              make them successful. We are customer-first. Always have been and
              always will be.
            </p>
          </div>
        </Grid>
      </Grid>
      <div className={styles.buttonPartner}>
        <button className={styles["btn-view"]} onClick={goTocomp}>
          View More
        </button>
      </div>
    </div>
  );
};

export default InterSystems;
