import { Button, Grid } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import GoToTop from "../../GoToTop";
import styles from "../../styles/RecruitementProcess.module.css";
import { FormattedMessage } from "react-intl";

export default function RecruitementProcess() {
  const history = useHistory();

  const openpositionHandler = () => {
    history.push({
      pathname: "/openpositions",
      state: true,
    });
  };

  return (
    <div className={styles["recruitement-process"]}>
      <GoToTop />
      <div style={{ overflow: "hidden" }}>
        <div className={styles["recruit-content"]}>
          <p className={styles["recruitement-content-p"]}>Careers</p>
          <h1 className={styles["recruitement-content-h1"]}>
            <FormattedMessage id="rec_process_h" />
          </h1>
        </div>
        <Grid container>
          <img
            src={process.env.PUBLIC_URL + "/assets/images/recruitment/rp.svg"}
            className={styles["recruitement-img"]}
            alt='recruit'
          />
        </Grid>

        <div className={styles["recruit-content1"]}>
          <p className={styles["recruit-content1-p"]}>
            <FormattedMessage id="our_philosophy_rec_p1" />
          </p>
          <p className={styles["recruit-content1-p1"]}>
            <FormattedMessage id="our_philosophy_rec_p2" />
          </p>

          <br />
          <p className={styles["recruit-content1-p"]}>
            <FormattedMessage id="our_thought_rec_p1" />
          </p>
          <p className={styles["recruit-content1-p1"]}>
            <FormattedMessage id="our_thought_rec_p2" />
          </p>
        </div>

        <div className={styles["recruit-img-block"]}>
          <div className={styles.recruitGrids}>
            <div className={styles["img-hover-zoom"]}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/recruitment/recruit1.svg"
                }
                alt='Recruitement'
              />
            </div>
            <div className={styles["img-hover-zoom"]}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/recruitment/recruit2.svg"
                }
                alt='Recruitement'
              />
            </div>
          </div>
        </div>

        <div className={styles["recruit-content1"]}>
          <p className={styles["recruit-content1-p"]}>
            <FormattedMessage id="interviewing_process_p" />
          </p>

          <p className={styles["recruit-content1-p1"]}>
            {" "}
            <FormattedMessage id="job_advertising_p1" />
          </p>
          <p className={styles["recruit-content2-p1"]}>
            <FormattedMessage id="job_advertising_p2" />
          </p>
          <p className={styles["recruit-content1-p1"]}>
            <FormattedMessage id="appl_screening_rec_p1" />
          </p>
          <p className={styles["recruit-content2-p1"]}>
            <FormattedMessage id="appl_screening_rec_p2" />
          </p>
          <p className={styles["recruit-content1-p1"]}>
            <FormattedMessage id="evaluation_rec_p" />
          </p>
          <p className={styles["recruit-content2-p1"]}>
            <FormattedMessage id="evaluation_rec_p1" />
          </p>

          <p className={styles["recruit-content1-p1"]}>
            <FormattedMessage id="selection_rec_p1" />{" "}
          </p>
          <p className={styles["recruit-content2-p1"]}>
            {" "}
            <FormattedMessage id="selection_rec_p2" />
          </p>

          <p className={styles["recruit-content1-p1"]}>
            <FormattedMessage id="offer_rec_p1" />{" "}
          </p>
          <p className={styles["recruit-content2-p1"]}>
            <FormattedMessage id="offer_rec_p2" />
          </p>

          <p className={styles["recruit-content1-p1"]}>
            <FormattedMessage id="position_rec_p1" />
          </p>
          <p className={styles["recruit-content2-p1"]}>
            {" "}
            <FormattedMessage id="position_rec_p2" />
          </p>

          <br />
          <p className={styles["recruit-content1-p"]}>
            <FormattedMessage id="be_yourself_rec_p1" />
          </p>
          <p className={styles["recruit-content1-p1"]}>
            <FormattedMessage id="be_yourself_rec_p2" />
          </p>
        </div>

        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className={styles["recruitimg"]}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/images/recruitment/rp2.svg"
                }
                alt='Recruitement'
                className={styles["recruit-img-block1"]}
              />
            </div>
          </Grid>
        </Grid>

        <div className={styles["recruit-content-block1"]}>
          <p className={styles["recruit-content1-p1"]}>
            <FormattedMessage id="no_load_rec_p" />
          </p>

          <p className={styles["recruit-content1-p3"]}>
            <FormattedMessage id="dhanesh_rec_p" />{" "}
          </p>
        </div>

        <div className={styles["recruit-img-block"]}>
          <div className={styles.recruitGrids}>
            <div className={styles["img-hover-zoom"]}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/recruitment/recruit3.svg"
                }
                alt='Recruitement'
              />
            </div>
            <div className={styles["img-hover-zoom"]}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/recruitment/recruit4.svg"
                }
                alt='Recruitement'
              />
            </div>
          </div>
        </div>

        <div className={styles["recruit-content1"]}>
          <p className={styles["recruit-content1-p"]}>
            <FormattedMessage id="experience_rec_p" />
          </p>
          <p className={styles["recruit-content1-p1"]}>
            <FormattedMessage id="experience_rec_p1" />
          </p>
        </div>

        <div className={styles["discover-btn"]}>
          <Button
            variant='outlined'
            type='submit'
            style={{ textTransform: "capitalize" }}
            onClick={openpositionHandler}
          >
            <FormattedMessage id="button_rec" />{" "}
          </Button>
        </div>
      </div>
    </div>
  );
}
