import React from 'react'
import { Grid } from '@mui/material'
import styles from '../../styles/StratergyAllience.module.css'
import { StratergyAlliences } from '../../../data'
import GoToTop from '../../GoToTop';
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl";

const StratergyAllience = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const lang = params.get("lang");
    return (
        <div className={styles.StratergyAllience_container}>
            <GoToTop />
            <div className={styles.main}>


                <p className={styles.heading}><FormattedMessage id='strategicalliances_Para'/></p>
                <Grid item
                    id='allience-part'
                    className={styles["allience-part"]}
                    container
                    spacing={2}>
                    {
                        StratergyAlliences.map(item => (
                            <Grid
                                key={item.id}
                                className={styles["allience-imgs"]}
                               
                                item
                                xl={4}
                                lg={4}
                                md={4}
                                sm={6}
                                xs={12}>
                                <div className=''>
                                    <div className={styles["img-hover-zoom"]}>
                                        <Link
                                        New
                                        Message
                                        to={{
                                          pathname: `/${item.id}`,
                                          search: `?lang=${lang}`,
                                        }}
                                        >
                                        <img
                                            className={styles["allience-imghover"]}
                                            id='imghover'
                                            src={process.env.PUBLIC_URL + item.img}
                                            alt={item.title}
                                            loading='lazy'
                                        />
                                        </Link>
                                    </div>
                                    <p className={styles.alliencetitle}>{item.title}</p>
                                    <h4 className={styles.alliencesubtitle}>{item.subTitle}</h4>
                                </div>
                            </Grid>
                        ))
                    }

                </Grid>

            </div>

        </div>
    )
}

export default StratergyAllience

