import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import styles from "../styles/Footer.module.css";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { FormattedMessage } from "react-intl";

export default function Footer() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const lang = params.get("lang");
  const history = useHistory();

  const caseClickHandler = () => {
    history.push("/casestudies");
  };
  const industriesClickHandler = () => {
    history.push("/industries");
  };
  const LocationClickHandler = () => {
    history.push("/locations");
  };
  useEffect(() => {}, []);
  const instaClickHAndler = (e) => {
    window.open(`https://www.instagram.com/datatemplate/`);
  };
  const facebookClickHAndler = (e) => {
    window.open(`https://www.facebook.com/DataTemplate`);
  };
  const youTubeClickHAndler = (e) => {
    window.open(`https://www.youtube.com/c/DatatemplateInfotech`);
  };
  const twitterClickHAndler = (e) => {
    window.open(`https://twitter.com/datatemplate `);
  };
  const lindinClickHAndler = (e) => {
    window.open(`https://in.linkedin.com/company/datatemplate`);
  };

  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={`${styles.footer} ${styles["footer-height"]}`}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xl={4}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={styles["left-element"]}
          >
            <h4 className={styles["footer-h"]}>
              <FormattedMessage id="company_header" />
            </h4>
            <ul className={styles["footer-ul"]}>
              <HashLink 
            New
            Message
            to={{
              pathname: "/aboutus",
              search: `?lang=${lang}`,
            }} 
            className={styles.link1}>
                <li>
                  <FormattedMessage id="about_us_footer" />
                </li>
              </HashLink>

              <HashLink 
              New
              Message
              to={{
                pathname: "/services",
                search: `?lang=${lang}`,
              }}
              className={styles.link1}>
                <li>
                  {" "}
                  <FormattedMessage id="services_footer" />
                </li>
              </HashLink>
              <li onClick={caseClickHandler} className={styles.link1}>
                <FormattedMessage id="case_studies_footer" />
              </li>
              <li onClick={industriesClickHandler} className={styles.link1}>
                <FormattedMessage id="industries_footer" />
              </li>
              <li onClick={LocationClickHandler} className={styles.link1}>
                <FormattedMessage id="location_footer" />
              </li>
            </ul>
          </Grid>
          <Grid
            item
            xl={4}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={styles["footer-people"]}
          >
            <h4 className={styles["footer-h"]}>
              <FormattedMessage id="people_footer" />
            </h4>
            <ul className={styles["footer-ul"]}>
              <HashLink 
              New
              Message
              to={{
                pathname: "/careers",
                search: `?lang=${lang}`,
              }}
              
              
              className={styles.link1}>
                <li>
                  <FormattedMessage id="careers_footer" />
                </li>
              </HashLink>
              <HashLink 
              New
              Message
              to={{
                pathname: "/datatemplate",
                search: `?lang=${lang}`,
              }}
              
              
              className={styles.link1}>
                <li>
                  <FormattedMessage id="life_at_data_template" />
                </li>
              </HashLink>
              <HashLink 
              New
              Message
              to={{
                pathname: "/blogs",
                search: `?lang=${lang}`,
              }}
              
              className={styles.link1}>
                <li>
                  <FormattedMessage id="blogs_footer" />
                </li>
              </HashLink>
              <HashLink  
              New
              Message
              to={{
                pathname: "/ourteam",
                search: `?lang=${lang}`,
              }}
              className={styles.link1}>
                <li>
                  <FormattedMessage id="our_team_footer" />
                </li>
              </HashLink>
            </ul>
          </Grid>
          <Grid item xl={4} lg={3} md={3} sm={12} xs={12}>
            <h4 className={styles["footer-h"]}>
              <FormattedMessage id="social_footer" />
            </h4>
            <ul className={styles["footer-ul"]}>
              <HashLink
              New
              Message
              to={{
                pathname: "/newsandevents",
                search: `?lang=${lang}`,
              }}
              
              className={styles.link1}>
                <li>
                  <FormattedMessage id="news_&_events_footer" />
                </li>
              </HashLink>
              <HashLink 
              New
              Message
              to={{
                pathname: "/testimonials",
                search: `?lang=${lang}`,
              }}
              className={styles.link1}>
                <li>
                  <FormattedMessage id="testimonials_footer" />
                </li>
              </HashLink>
              <HashLink
              New
              Message
              to={{
                pathname: "/privacypolicy",
                search: `?lang=${lang}`,
              }}
              className={styles.link1}>
                <li>
                  <FormattedMessage id="privacy_policy_footer" />
                </li>
              </HashLink>
              <HashLink  
              New
              Message
              to={{
                pathname: "/reachouttous",
                search: `?lang=${lang}`,
              }}
              
              className={styles.link1}>
                <li>
                  <FormattedMessage id="contact_us" />
                </li>
              </HashLink>

              <ul
                className={`${styles["social-networks"]} ${styles["social-circle"]} ${styles["socialIcons"]}`}
              >
                <li onClick={facebookClickHAndler}>
                  <img
                    className={styles["social-icons"]}
                    src="/assets/images/footericons/facebook.svg"
                    alt="facebook"
                  ></img>
                </li>
                <li onClick={twitterClickHAndler}>
                  <img
                    className={styles["social-icons"]}
                    src="/assets/images/footericons/twitter.svg "
                    alt="twitter"
                  ></img>
                </li>

                <li onClick={lindinClickHAndler}>
                  <img
                    className={styles["social-icons"]}
                    src="/assets/images/footericons/linkdin.svg"
                    alt="Youtube"
                  ></img>
                </li>
                <li onClick={instaClickHAndler}>
                  <img
                    className={styles["social-icons"]}
                    src="/assets/images/footericons/Instagram logo Svg.svg"
                    alt="Instagram"
                  ></img>
                </li>
                <li onClick={youTubeClickHAndler}>
                  <img
                    className={styles["social-icons"]}
                    src="/assets/images/footericons/youtube.svg"
                    alt="Youtube"
                  ></img>
                </li>
              </ul>
            </ul>
          </Grid>
          <p className={styles.copyRight}>
            {" "}
            &#169; {new Date().getFullYear()}{" "}
            <FormattedMessage id="all_rights_footer" />
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
}
