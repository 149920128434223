import React, { Suspense, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter,
} from "react-router-dom";
import Header from "./components/views/Header";
import Footer from "./components/views/Footer";
import LoadingSpinner from "./components/LoadingSpinner";
import Industries from "./components/views/Industries/Industries";
import AboutUs from "./components/views/AboutUs/AboutUs";
import CaseStudies from "./components/views/CaseStudies/CaseStudies";
import Services from "./components/views/Services/Services";
import Clients from "./components/views/Clients/Clients";
import NewsEvents from "./components/views/News&Events/NewsEvents";
import Testimonials from "./components/views/Testimonials/Testimonials";
import Location from "./components/views/Location/Location";
import Blog from "./components/views/Blog/Blog";
import ReachOut from "./components/views/ReachOutToUs/ReachOut";
import Benefits from "./components/views/Benefits/Benefits";
import OpenPosition from "./components/views/OpenPosition/OpenPosition";
import Sustainability from "./components/views/Sustainability/Sustainability";
import Policy from "./components/views/PrivacyPolicy/Policy";
import DataTemplate from "./components/views/DataTemplate/DataTemplate";
import Lookfor from "./components/views/Whatwelook/Lookfor";
import Enterprise from "./components/views/Enterprise/Enterprise";
import Network from "./components/views/NetworkSpeed/Network";

import OpenPosition1 from "./components/views/OpenPosition1/OpenPosition1";
import RecruitementProcess from "./components/views/RecurimentProcess/RecruitementProcess";
import StratergyExecution from "./components/views/StratergyExecution/StratergyExecution";
import Inovation from "./components/views/Inovation/Inovation";
import WeStandTogether from "./components/views/WeStandTogetherHate/WeStandTogether";
import Certification from "./components/views/Certification/Certification";
import StratergyAllience from "./components/views/stratergyAllience/StratergyAllience";
import Partner from "./components/views/Partner/Partner";
import OurTeam from "./components/views/OurTeam/OurTeam";
import Jobdesc from "./components/views/JobDesc/Jobdesc";
import TermUse from "./components/views/termUse/TermUse";
import YellowPage from "./components/views/YellowPages/YellowPage";
import InterSystems from "./components/views/InterSystem/InterSystems";
import ClinAlly from "./components/views/ClinAlly/ClinAlly";
import Astra from "./components/views/Astra/Astra";
import CyberShelter from "./components/views/Cybershelter/CyberShelter";
import AI from "./components/views/BlogDrillDowns/AI";
import BI from "./components/views/BlogDrillDowns/BI";
import Biometrics from "./components/views/BlogDrillDowns/Biometrics";
import Blockchain from "./components/views/BlogDrillDowns/Blockchain";
import Collaborative from "./components/views/BlogDrillDowns/Collaborative";
import Cryptocurrency from "./components/views/BlogDrillDowns/Cryptocurrency";
import Pycharm from "./components/views/BlogDrillDowns/Pycharm";
import Gaming from "./components/views/BlogDrillDowns/Gaming";
import Ozone from "./components/views/Ozone/Ozone";
import Web from "./components/views/BlogDrillDowns/Web";
import Teamops from "./components/views/BlogDrillDowns/Teamops";
import Career from "./components/views/Careers/Career";
import Indigital from "./components/views/Indigital/Indigital";
import Envizage from "./components/views/Envizage/Envizage";
import YourGrantAgency from "./components/views/YourGrantAgency/YourGrantAgency";
import { IntlProvider } from "react-intl";
// import LanguageSelector from "./components/views/";
import translationsEn from "./locales/en/translation.json";
import translationsEs from "./locales/es/translation.json";

const Home = React.lazy(() => import("./components/views/Home-page/Home"));

function RouteComponent(props) {
  const [darkBackground, setDarkBackground] = React.useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const lang = params.get("lang");
  const [currentLanguage, setCurrentLanguage] = React.useState(
    lang ? lang : "en"
  );
  const translations =
    currentLanguage === "en" ? translationsEn : translationsEs;

  const location = useLocation().pathname;

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: location,
        title: location.length > 1 ? location.split("/")[1] : "Home",
      },
    });
    if (location && location.pathname !== "/") {
      setDarkBackground(false);
    }

    if (location && location.pathname === "/") {
      setDarkBackground(true);
    }
  }, [location]);
  return (
    <div style={{ backgroundColor: '#000', }}>
      <IntlProvider
        key={currentLanguage}
        locale={currentLanguage}
        messages={translations}
      >
        <Suspense fallback={<LoadingSpinner />}>
          <Header
            darkBackground={darkBackground}
            setDarkBackground={setDarkBackground}
            setCurrentLanguage={setCurrentLanguage}
            currentLanguage={currentLanguage}
          />
          <main>
            <Switch history={props.history}>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/careers" history={props.history}>
                <Career />
              </Route>
              <Route exact path="/industries" history={props.history}>
                <Industries currentLanguage={currentLanguage} />
              </Route>
              <Route exact path="/casestudies/:industry">
                <CaseStudies currentLanguage={currentLanguage} />
              </Route>
              <Route exact path="/aboutus">
                <AboutUs currentLanguage={currentLanguage} />
              </Route>
              <Route exact path="/casestudies">
                <CaseStudies currentLanguage={currentLanguage} />
              </Route>
              <Route exact path="/services">
                <Services currentLanguage={currentLanguage} />
              </Route>
              <Route exact path="/clients">
                <Clients />
              </Route>
              <Route exact path="/newsandevents">
                <NewsEvents currentLanguage={currentLanguage} />
              </Route>
              <Route exact path="/testimonials">
                <Testimonials />
              </Route>
              <Route exact path="/locations">
                <Location />
              </Route>
              <Route exact path="/blogs">
                <Blog currentLanguage={currentLanguage} />
              </Route>
              <Route exact path="/reachouttous">
                <ReachOut />
              </Route>
              <Route exact path="/openpositions">
                <OpenPosition />
              </Route>
              <Route exact path="/perksandbenefits">
                <Benefits currentLanguage={currentLanguage} />
              </Route>
              <Route exact path="/sustainability">
                <Sustainability />
              </Route>
              <Route exact path="/privacypolicy">
                <Policy />
              </Route>
              <Route exact path="/datatemplate">
                <DataTemplate />
              </Route>
              <Route exact path="/whatwelookfor">
                <Lookfor />
              </Route>
              <Route exact path="/enterprise">
                <Enterprise />
              </Route>
              <Route exact path="/casestudies/caseInfo/:id">
                <Network currentLanguage={currentLanguage} />
              </Route>

              <Route exact path="/recruitmentprocess">
                <RecruitementProcess />
              </Route>
              <Route exact path="/strategytoexecution">
                <StratergyExecution />
              </Route>
              <Route exact path="/bandofinnovators">
                <Inovation />
              </Route>
              <Route exact path="/ourpeople">
                <WeStandTogether currentLanguage={currentLanguage} />
              </Route>
              <Route exact path="/certifications">
                <Certification />
              </Route>
              <Route exact path="/strategicalliances">
                <StratergyAllience />
              </Route>
              <Route exact path="/whiteboard">
                <Partner />
              </Route>
              <Route exact path="/indigital">
                <Indigital />
              </Route>
              <Route exact path="/envizage">
                <Envizage />
              </Route>
              <Route exact path="/yourgrantagency">
                <YourGrantAgency />
              </Route>
              <Route exact path="/ourteam">
                <OurTeam currentLanguage={currentLanguage} />
              </Route>
              <Route exact path="/jobdesc">
                <Jobdesc />
              </Route>
              <Route exact path="/openpositions/jobdesc/:id">
                <OpenPosition1 />
              </Route>
              <Route exact path="/termsofuse">
                <TermUse />
              </Route>
              <Route exact path="/yellowpage">
                <YellowPage />
              </Route>
              <Route exact path="/intersystems">
                <InterSystems />
              </Route>
              <Route exact path="/clinally">
                <ClinAlly />
              </Route>
              <Route exact path="/astra">
                <Astra />
              </Route>
              <Route exact path="/cybershelter">
                <CyberShelter />
              </Route>
              <Route exact path="/augmentedintelligence">
                <AI />
              </Route>
              <Route exact path="/businessintelligence">
                <BI />
              </Route>
              <Route exact path="/biometrics">
                <Biometrics />
              </Route>
              <Route exact path="/blockchain">
                <Blockchain />
              </Route>
              <Route exact path="/collaborative">
                <Collaborative />
              </Route>
              <Route exact path="/cryptocurrency">
                <Cryptocurrency />
              </Route>
              <Route exact path="/pycharm">
                <Pycharm />
              </Route>
              <Route exact path="/gaming">
                <Gaming />
              </Route>
              <Route exact path="/ozone">
                <Ozone />
              </Route>
              <Route exact path="/web">
                <Web />
              </Route>
              <Route exact path="/hrteamops">
                <Teamops />
              </Route>
              <Route exact path="/aiservices">
                <Services currentLanguage={currentLanguage} />
              </Route>
              <Route exact path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </main>
          <Footer />
        </Suspense>
      </IntlProvider>
    </div>
  );
}

export default withRouter(RouteComponent);
