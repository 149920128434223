import React, { useRef, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import styles from "../../styles/Services.module.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";
import * as data from "../../../data.js";
import GoToTop from "../../GoToTop";
import { FormattedMessage } from "react-intl";

export default function Services({ currentLanguage }) {
  const dataModules = require.context("../../../data", false, /\.js$/);
  console.log(dataModules, "dataModules");
  const languageData = {};

  dataModules.keys().forEach((key) => {
    const langCode = key.replace(/^\.\/(.+)\.js$/, "$1");
    languageData[langCode] = dataModules(key);
  });
  const [data, setData] = useState(languageData[currentLanguage]);

  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  const revealsSecondRef = useRef([]);
  revealsFirstRef.current = [];
  revealsSecondRef.current = [];
  const isAiServices = window.location.href.includes("aiservices")
  useEffect(()=>{
    if(isAiServices){
      setTimeout(() => {
        document.getElementById('ML').scrollIntoView();
      }, 300);      
    }    
  },[isAiServices])

  useEffect(() => {

    revealsFirstRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });

  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };


  const refs = data.serviceimg.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  return (
    <>
      <GoToTop/>
      <div className={styles.services}>
        
        <div className={styles["services-img"]}>
          <div className={styles["service-title"]}>
            <h1 className={styles.serviceheading}>
              <FormattedMessage id="heading_services" />
            </h1>
            <p className={styles.serviceheadingp}>
              <FormattedMessage id="paragraph_service" />
            </p>
          </div>
          <Grid className={styles["service-part"]} container spacing={2}>
            {data.serviceimg.map((item, index) => {
              return (
                <Grid
                  key={item.id}
                  className={styles.Serviceimages}
                  ref={(refs[item.id], addToFirstRef)}
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <div className="" id={item.id}>
                    <div className={styles["img-hover-zoom"]}>
                    <img className={styles["service-img"]} id='imghover' src={process.env.PUBLIC_URL + item.img} alt={item.title} loading="lazy"/>
                    </div>
                    <h4 className={styles.servicetitle2}>{item.title}</h4>

                    <Link to={`/casestudies/${item.serviceType}`}>
                      <button
                        className={styles["btn-view"]}
                        ref={addToFirstRef}
                      >
                        <FormattedMessage id="button_service" />
                      </button>
                    </Link>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
        
      </div>
    </>
  );
}
