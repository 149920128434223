import { Button, Grid } from "@mui/material";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import GoToTop from "../../GoToTop";
import { FormattedMessage } from "react-intl";

import styles from  "../../styles/Lookfor.module.css";

export default function Lookfor() {

  const history = useHistory()
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const  whatwelookfor = useRef(null);

  useEffect(()=>{
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
  },[]);

  useEffect(() => {
    gsap.fromTo(
      whatwelookfor.current,
      1.5,
      {
        width: "80%",
        marginLeft: "10%",
        delay: 0,

      },
      {
        width: "100%",
        marginLeft: 0,
        delay: 0,
      }
    );
  }, []);

  const clickHandler = () => {
    history.push({
      pathname:'/openpositions',
      state:true
    })
  }



  return (
    <div className={styles["lookFor_container"]}>
      <GoToTop />
      <div className={styles.Lookfor}>
        <div ref={detailRef}>
          <div className={styles["Lookfor-title"]}>
            <p>
              <FormattedMessage id="what_we_look_head" />
            </p>
            <h1 className={styles["Lookfor-H"]}>
              <FormattedMessage id="what_we_look_para" />
            </h1>
          </div>
        </div>

        <div>
          <Grid container>
            <Grid item>
              <div className={styles["Lookfor-items"]}>
                <div ref={whatwelookfor}>
                  <img
                    className={styles["Lookfor-items-img"]}
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/lookfor/whatwelookfor.svg"
                    }
                    alt='what we Look For'
                    loading='lazy'></img>
                </div>

                <div ref={detailRef}>

                  <p className={styles["Lookfor-items-p"]}>
                    <FormattedMessage id="what_we_look_p1" />
                  </p>
                  <p className={styles["Lookfor-items-p1"]}>
                    <FormattedMessage id="what_we_look_p2" />
                  </p>
                  <p className={styles["Lookfor-items-p2"]}>
                    <FormattedMessage id="what_we_look_p3" />
                  </p>

                  <p className={styles["Lookfor-items-p1"]}>
                    <FormattedMessage id="what_we_look_p4" />
                  </p>

                  <p className={styles["Lookfor-items-p2"]}>
                    <FormattedMessage id="what_we_look_p5" />
                  </p>

                  <h6 className={styles["adven-h6"]}>
                    <FormattedMessage id="adventurous_h_whatwelook" />
                  </h6>
                  <p className={styles["Lookfor-items-p2"]}>
                    <FormattedMessage id="adventurous_p_whatwelook" />
                  </p>

                  <h6 className={styles["adven-h6"]}>
                    <FormattedMessage id="imaginative_h_whatwelook" />
                  </h6>
                  <p className={styles["Lookfor-items-p2"]}>
                    <FormattedMessage id="imaginative_p_whatwelook" />
                  </p>

                  <h6 className={styles["adven-h6"]}>
                    <FormattedMessage id="passionate_h_whatwelook" />
                  </h6>
                  <p className={styles["Lookfor-items-p2"]}>
                    <FormattedMessage id="passionate_p_whatwelook" />
                  </p>

                  <h6 className={styles["adven-h6"]}>
                    <FormattedMessage id="leadership_h_whatwelook" />
                  </h6>
                  <p className={styles["Lookfor-items-p2"]}>
                    <FormattedMessage id="leadership_p_whatwelook" />
                  </p>

                  <h6 className={styles["adven-h6"]}>
                    <FormattedMessage id="flexibility_h_whatwelook" />
                  </h6>
                  <p className={styles["Lookfor-items-p2"]}>
                    <FormattedMessage id="flexibility_p_whatwelook" />
                  </p>

                  <h6 className={styles["adven-h6"]}>
                    <FormattedMessage id="conscientiousness_h" />
                  </h6>
                  <p className={styles["Lookfor-items-p2"]}>
                    <FormattedMessage id="conscientiousness_p" />
                  </p>
                  <br />

                  <Button variant="outlined" className={styles.LookforButton} type='submit' onClick={clickHandler}><FormattedMessage id="what_we_look_button"/></Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
