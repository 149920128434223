import { Grid } from "@mui/material";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import GoToTop from "../../GoToTop";
import styles from "../../styles/Enterprise.module.css";
import { FormattedMessage } from "react-intl";

export default function Web() {
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const detailRef1 = useRef(null);
 
  useEffect(()=>{
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    const element1 = detailRef1.current;
    gsap.fromTo(
      element1,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
  },[])

 

  return (
    <div className={styles.web_container}>
      <GoToTop />
      <Grid container className={styles.ElementEnterpriseFirst} ref={detailRef}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          style={{ paddingTop: "28px" }}>
          <div className={styles["enterprise-block"]} >
           
            <div>
              <h3 className={styles["enterprise-h3"]}>
                   <FormattedMessage id="web_heading"/>
              </h3>
            </div>
           
          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}    >
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/images/blogdrill/web/web1.svg"
            }
            className={styles["enterprise-img"]}
            alt='enterprise'
          />
        </Grid>
      </Grid>

      <div className={styles.enterpriseMain} ref={detailRef1}>
        <Grid container className={styles.ElementEnterprises}>
          <Grid item>
      
          </Grid>
          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}>
                <FormattedMessage id="web_h"/>
              </h3>

              <p style={{fontSize: "19px"}}>
              <FormattedMessage id="web_p"/>
              </p>

              <h3 className={styles.PHeading}>
                <FormattedMessage id="web_h1"/>
              </h3>

              <p style={{fontSize: "19px"}}>
              <FormattedMessage id="web_p1"/>
          </p>
          <p style={{fontSize: "19px"}}>
            <FormattedMessage id="web_p2"/>
         </p>

<p style={{fontSize: "19px"}}><FormattedMessage id="web_p3"/></p>
            </div>
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}>
                <FormattedMessage id="web_h2"/>
              </h3>

              <p style={{fontSize: "19px"}}>
              <FormattedMessage id="web_solve_p"/>

              </p>
         
            </div>
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
              <h3 className={styles.PHeading}><FormattedMessage id="identity_head"/></h3>

              <p style={{fontSize: "19px"}}>
              <FormattedMessage id="identity_para"/>
              </p>
             
            </div>
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ paddingTop: "28px" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/blogdrill/web/web2.svg"
              }
              className={styles["enterprise-img"]}
              alt='enterprise'
            />
          </Grid>
        </Grid>
        <Grid container className={styles.ElementEnterprise}>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={12}
            style={{ margin: "auto" }}></Grid>

          <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
            <div>
            <h3 className={styles.PHeading}><FormattedMessage id="web_censorship_head"/></h3>

              <p style={{fontSize: "19px"}}>
              <FormattedMessage id="web_censorship_para"/>
              </p>

              <h3 className={styles.PHeading}><FormattedMessage id="web_differential_pri_h"/></h3>

              <p style={{fontSize: "19px"}}><FormattedMessage id="web_differential_pri_p"/></p>

<p style={{fontSize: "19px"}}><FormattedMessage id="web_differential_pri_p1"/></p>
            
            </div>
          </Grid>
        </Grid>
        
      </div>
    </div>
  );
}
