import React, { useRef, useEffect,useState} from "react";
import Grid from "@mui/material/Grid";
import styles from "../../styles/News&Events.module.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as data from "../../../data.js";
import GoToTop from "../../GoToTop";
import { FormattedMessage } from "react-intl";


export default function NewsEvents({ currentLanguage }) {
  const dataModules = require.context("../../../data", false, /\.js$/);
  console.log(dataModules, "dataModules");
  const languageData = {};

  dataModules.keys().forEach((key) => {
    const langCode = key.replace(/^\.\/(.+)\.js$/, "$1");
    languageData[langCode] = dataModules(key);
  });
  const [data, setData] = useState(languageData[currentLanguage]);
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  const revealsSecondRef = useRef([]);
  revealsFirstRef.current = [];
  revealsSecondRef.current = [];

  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
    revealsFirstRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };
  const lindinClickHAndler = (e) => {
    window.open(`https://in.linkedin.com/company/datatemplate`);
  };
  const instaClickHAndler = (e) => {
    window.open(`https://www.instagram.com/datatemplate/`);
  };

 

  return (
    <div className={styles.newsEvent_container}>
      <GoToTop />
      <div className={styles.News}>
        <div ref={detailRef} className={styles.contRes}>
          <div className={styles["news-title"]}>
            <h2 className={styles["newsHeading-1"]}>
              <FormattedMessage id="news_events_head"/>
            </h2>
          </div>
          <div className={styles["news-content"]}>
            <p className={styles["news-contentp"]}>
              <FormattedMessage id="news_events_para"/>
            </p>{" "}
          </div>
        </div>
        <div className={styles["News-Event"]}>
          <div className={`{styles["news-img"]} ${styles.news}`} id='news' style={{backgroundColor: "#000"}}>
            <h5 className={styles["news-H"]} ref={addToFirstRef}>
              <FormattedMessage id="news_events_h"/>
            </h5>
            <Grid
              id='news-part'
              className={styles["news-part"]}
              container
              spacing={2}
            >
              {data.newsimg.map((item, index) => {
                return (
                  <Grid
                    className={styles.newsimages}
                    key={index}
                    ref={addToFirstRef}
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}>
                    <div className=''>
                      <div className={styles["img-hover-zoom"]}>
                       
                        <img
                          className={styles["news-img"]}
                          id='imghover'
                          src={process.env.PUBLIC_URL + item.img}
                          alt={item.title}
                          loading='lazy'
                          
                        />
                       
                      </div>
                      <p className={styles.newstitle}>{item.title}</p>
                      <h4 className={styles.newssubtitle}>{item.subTitle}</h4>
                    </div>
                  </Grid>
                );
              })}
              <button
                className={styles["btn-view"]}
                ref={addToFirstRef}
                onClick={lindinClickHAndler}>
                <FormattedMessage id="news_events_button"/>
              </button>
            </Grid>
          </div>

          <div className={styles["events-img"]} id='events'>
            <h5 className={styles["events-H"]} ref={addToFirstRef}>
              <FormattedMessage id="events_h"/>
            </h5>
            <Grid
              id='events-part'
              className={styles["events-part"]}
              container
              spacing={2}>
              {data.eventsimg.map((item, index) => {
                return (
                  <Grid
                    className={styles.eventsimages}
                    key={index}
                    ref={addToFirstRef}
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}>
                    <div className=''>
                      <div className={styles["img-hover-zoom"]}>
                        <img
                          className={styles["events-img"]}
                          id='imghover'
                          src={item.img}
                          alt="eventimg"
                        />
                      </div>
                      <p className={styles.eventstitle}>{item.title}</p>
                      <h4 className={styles.eventssubtitle}>{item.subTitle}</h4>
                    </div>
                  </Grid>
                );
              })}
              <button
                className={styles["btn-view"]}
                ref={addToFirstRef}
                onClick={instaClickHAndler}>
                <FormattedMessage id="news_events_button"/>
              </button>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
