import { Grid } from "@mui/material";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import GoToTop from "../../GoToTop";
import * as data from "../../../data.js";
import styles from '../../styles/OpenPosition.module.css'
import LoadingSpinner from "../../LoadingSpinner";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Search from "../CustomSearch/Search";
import { useIntl } from 'react-intl';
import { FormattedMessage } from "react-intl";


export default function OpenPosition(props) {
  const [checkedItems, setCheckedItems] = useState({});
  const [checkedLocation, setCheckedLocation] = useState({});
  const [chooseDeptartment, setChooseDepartment] = useState({});
  const [chooseLocation, setChooseLocation] = useState({});
  const [searchPosition, setSearchPosition] = useState('');
  const [departments, setDepartments] = useState(false)
  const [location, setLocaton] = useState(false)
  const [careerData, setCareerData] = React.useState(null);
  const [careerCategories, setCareerCategeries] = React.useState(null);
  const [careerLink, setCareerLink] = React.useState(null);
  const [careerCatLink, setCareerCatLink] = React.useState(null);
  const [bngLocPosts, setBngLocPosts] = React.useState([]);
  const [almLocPosts, setAlmLocPosts] = React.useState([]);
  const [lonLocPosts, setlonLocPosts] = React.useState([]);
  const [cinLocPosts, setcinLocPosts] = React.useState([]);
  const [manLocPosts, setmanLocPosts] = React.useState([]);
  const [showData, setNewData] = useState(5);
  const [showDataLoc, setNewDataLoc] = useState(5);
  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: 'openposition_search' });
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);

  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );
  }, []);

  useEffect(() => {
    if (data && data.wpData && data.wpData[0].categoriesLink) {
      setCareerCatLink(data.wpData[0].categoriesLink);
    }

    if (data && data.wpData && data.wpData[0].postsLink) {
      setCareerLink(data.wpData[0].postsLink);
    }
  }, [data]);

  useEffect(() => {
    fetch(careerCatLink,{

    })
      .then((res) => res.json())
      .then(
        (result) => {
          setCareerCategeries(result);
        },
        (error) => {
         setCareerCategeries(null)
        }
      );
  }, [careerCatLink]);

  useEffect(() => {
    fetch(careerLink)
      .then((res) => res.json())
      .then(
        (result) => {
          setCareerData(result);
          
        },
        (error) => {
          
          setCareerData(null);
        }
      );
  }, [careerLink]);

  const searchPositionHandler = (e) => {
    setSearchPosition(e.target.value)
  }






  const DeaprmentHandler = () => {
    setDepartments(!departments)
  }
  const LocationHandler = () => {
    setLocaton(!location)
  }
 

  let bngPostArr = [];
  let almPostArr = [];
  let cinPostArr = [];
  let lonPostArr = [];
  let manPostArr = [];
  
  useEffect(() => {
    if (
      careerCategories &&
      careerCategories.length > 0 &&
      careerData &&
      careerData.length > 0
    ) {
      var BangaloreLoc = careerCategories.find((c) => c.name === "Bangalore");
      var CincinnatiLoc = careerCategories.find((c) => c.name === "Cincinnati");
      var LondonLoc = careerCategories.find((c) => c.name === "London");
      var AlmatyLoc = careerCategories.find((c) => c.name === "Almaty");
      var MangaloreLoc = careerCategories.find((c) => c.name === "Mangalore");
      
     
      careerData.forEach((element) => {
        if (element.categories.length > 0) {
          element.categories.forEach((catEle) => {
            switch (catEle) {
              case BangaloreLoc?.id:
                bngPostArr.push(element);
                break;
              case CincinnatiLoc?.id:
                cinPostArr.push(element);
                break;
              case AlmatyLoc?.id:
                almPostArr.push(element);
                break;
              case LondonLoc?.id:
                lonPostArr.push(element);
                break;
                case MangaloreLoc?.id:
                  manPostArr.push(element);
                  break;
            
              default:
                break;
            }
          });
        }
      });
    }

  }, [careerCategories, careerData]);
  useEffect(() => {
    if (bngPostArr.length > 0) {
      setBngLocPosts(bngPostArr);
    }

  }, [bngPostArr]);

  useEffect(() => {
    if (almPostArr.length > 0) {
      setAlmLocPosts(almPostArr);
    }
  }, [almPostArr]);

  useEffect(() => {
    if (cinPostArr.length > 0) {
      setcinLocPosts(cinPostArr);
    }
  }, [cinPostArr]);

  useEffect(() => {
    if (lonPostArr.length > 0) {
      setlonLocPosts(lonPostArr);
    }
  }, [lonPostArr]);
  useEffect(() => {
    if (manPostArr.length > 0) {
      setmanLocPosts(manPostArr);
    }
  }, [manPostArr]);


  
const deptCheck = (event) => {
  setCheckedItems({...checkedItems, [event.target.id] : event.target.checked });
}
const locCheck = (event) => {
  setCheckedLocation({...checkedLocation, [event.target.id] : event.target.checked });
}


useEffect(() => {
  var keysDept = Object.keys(checkedItems);
  var keysloc = Object.keys(checkedLocation);
var filteredDepartments = keysDept.filter(function(key) {
    return checkedItems[key]
});
var filteredLocation = keysloc.filter(function(key) {
  return checkedLocation[key]
});
setChooseDepartment(filteredDepartments)
setChooseLocation(filteredLocation)
}, [checkedItems,checkedLocation]);  

  const bangaloreData = bngLocPosts.map((item) => {
    return {
      ...item,
      Location: "Bangalore",
      place: item._embedded["wp:term"].flat(2)?.filter(item => item.name !== 'Careers'),
      Department: item.title.rendered,
      DepartmentId: item._embedded["wp:term"].flat(2)
    }
  })
  const almatyData = almLocPosts.map((item) => {
    return {
      ...item,
      location: "Almaty",
      Department: item.title.rendered,
      DepartmentId: item._embedded["wp:term"].flat(2)
    }
  })
  const londonData = lonLocPosts.map((item) => {
    return {
      ...item,
      location: "London",
      Department: item.title.rendered,
      DepartmentId: item._embedded["wp:term"].flat(2)
    }
  })
  const cincinnatiData = cinLocPosts.map((item) => {
    return {
      ...item,
      location: "Cincinnati",
      Department: item.title.rendered,
      DepartmentId: item._embedded["wp:term"].flat(2)
    }
  })
    const MangaloreData = manLocPosts.map((item) => {
      return {
        ...item,
        place: item._embedded["wp:term"].flat(2)?.filter(item => item.name !== 'Careers'),
        Location: "Mangalore",
        Department: item.title.rendered,
        DepartmentId: item._embedded["wp:term"].flat(2)
      }
  })
  const finalArray = React.useMemo(()=>{
    return [...bangaloreData,...almatyData,...londonData,...cincinnatiData,...MangaloreData]
  },[bngLocPosts, almLocPosts, lonLocPosts, cinLocPosts,manLocPosts]);

  const [filteredData, updateFilteredData] = React.useState(finalArray);
  var notmatching = null;
  React.useEffect(()=>{
    updateFilteredData(finalArray);
    const filtData = finalArray && finalArray.length > 0 && finalArray?.filter((finarr)=>{
   
      if(chooseDeptartment.length < 1 && chooseLocation.length < 1){
        return finarr.Department.toLowerCase().includes(searchPosition.toLowerCase()) || finarr.Location.toLowerCase().includes(searchPosition.toLowerCase())
      }
      if(chooseDeptartment.length < 1 && chooseLocation.length > 0){
        return chooseLocation.map((ele)=>ele.toLowerCase()).includes(finarr.Location.toLowerCase()) && (finarr.Department.toLowerCase().includes(searchPosition.toLowerCase()) || finarr.Location.toLowerCase().includes(searchPosition.toLowerCase()))
      }

      return finarr.DepartmentId?.some((dept)=>{
    return chooseDeptartment.some((v)=>{
      return v.includes(dept.id.toLocaleString()) && (finarr.Department.toLowerCase().includes(searchPosition.toLowerCase()) || finarr.Location.toLowerCase().includes(searchPosition.toLowerCase()))
   })
        
      })
    });
    (filtData.length > 0) ? updateFilteredData(filtData) : updateFilteredData([{nodata: true}])
  }, [chooseDeptartment, chooseLocation,finalArray, searchPosition])
 

  


  
const deptCat = careerCategories && careerCategories?.filter(item => item.name === ".Net" || item.name === "Open Source")



const viewMoreDepartmentHandler = () => {
  setNewData(finalArray.length)
}
const viewlessDepartmentHandler = () => {
  setNewData(5)
}
const viewMoreLocationHandler = () => {
  setNewDataLoc(finalArray.length)
}
const viewLessLocationHandler = ()=> {
  setNewDataLoc(5)
}
function uncheckAll() {
  document.querySelectorAll('input[type="checkbox"]')
    .forEach(el => el.checked = false);
}
const clearFilter = (e) => {
  uncheckAll()
  setCheckedItems({})
  setCheckedLocation({})
  setChooseDepartment([])
  setChooseLocation([])
  updateFilteredData(filteredData)
}

  return (
    <div className={styles["openPosition_container"]}>
      <GoToTop />
      <div className={styles["input-icon my-3 ml-auto"]}  >
        <div ref={detailRef} className={styles["block"]}>
          <Search
            id='search'
            type='search'
            
            placeholder={placeholder}
            tabIndex='1'
            
            onChange={searchPositionHandler}
            closeBtn={true}
            path='/careers'
            />
          <Grid className={styles.positionContainer} container  spacing={3}>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12} className={styles.editor}>
              <div className={styles["editor-item1"]}>
                <p><FormattedMessage id="open_roles_openposition"/></p>
                <p style={{cursor:'pointer'}} onClick={clearFilter}><FormattedMessage id="clear_openposition"/></p>
              </div>
              <div className={styles["editor-item1"]}>
                <p><FormattedMessage id="department_openposition"/></p>
                <p className={styles["dept-line"]} onClick={DeaprmentHandler}>{departments ? <RemoveIcon className={styles.iconbutton}/> :<AddIcon className={styles.iconbutton}/>}</p>
              </div>
              {departments && <div>
                {deptCat && deptCat.slice(0,showData).map((item,i) => (
                  <div className={styles["editor-item"]} key={i}>
                    <p className={styles.ContentDesc}>{item.name}</p>
                    <input type="checkbox" className={styles["checkbox-editor"]} id={item.id} onClick={deptCheck}/>
                  </div>
                ))}
                <div className={styles.ContentDesc}>
                 {showData === 5 ? <p style={{ cursor: 'pointer' }} onClick={viewMoreDepartmentHandler}>View More</p> :
                 <p style={{ cursor: 'pointer' }} onClick={viewlessDepartmentHandler}>View Less</p> }
                </div>
              </div>}

              <div className={styles["editor-item1"]}>
                <p><FormattedMessage id="location_openposition"/></p>
                <p className={styles["dept-line"]} onClick={LocationHandler}>{location ? <RemoveIcon className={styles.iconbutton}/> :<AddIcon className={styles.iconbutton}/>}</p>
              </div>

              {location && <div>
                {data.LocationData && data.LocationData.slice(0,showDataLoc).map((item,i) => (
            <div className={styles["editor-item"]} key={i}>
            <p className={styles.ContentDesc}>{item.country}</p>
            <input type="checkbox" className={styles["checkbox-editor"]} id={item.country} onClick={locCheck}/>
            </div>
          ))}  
                <div className={styles.ContentDesc}>
                {showDataLoc === 5 ?  <p style={{ cursor: 'pointer' }} onClick={viewMoreLocationHandler}>View Less</p> :
                <p style={{ cursor: 'pointer' }} onClick={viewLessLocationHandler}>View More</p> }
                </div>
              </div>}

            </Grid>

            <Grid item xl={8} lg={8} md={8} sm={6} xs={12} className={styles.position}>
              {finalArray && filteredData.map((item,i) => (
                <>{finalArray.length > 0  && item?.nodata ? (
                  <h1 style={{ textAlign: 'center' }} ><p><FormattedMessage id="nojob_openposition"/></p></h1>
                ) : (
                  <Link style={{textDecoration:'none'}}to={{ pathname:"/Jobdesc",state:item}} key={i}> <div className={styles["position-editor"]}  value={item}>
                    <p>{item.Department}</p>
                    <p>{item.Location}</p>
                  </div></Link>)}
                </>
              ))}
             
             
              {finalArray.length < 0 && <h1 style={{textAlign:'center'}}><LoadingSpinner /></h1> }
              {careerData === null && (
                  <h1 style={{ textAlign: 'center' }} ><p><FormattedMessage id="nojob_openposition"/></p></h1>
                ) }
            </Grid>
          </Grid>
        </div></div>
    </div>
  )
}