import React from "react";
import GoToTop from "../../GoToTop";
import styles from "../../styles/termUse.module.css";

export default function Policy() {
  return (

      <div className={styles.policy}>
              <GoToTop />
          <div className={styles["policy-title"]}>
            <h2 className={styles.policyHeading}>Terms of Use</h2>
            <p className={styles["policy-title-p"]}>
            Data Template is a software company that considers your privacy seriously and processes your personal data in compliance with jurisdiction's laws. Below is some information about how we process your personal data:
            </p>
            <p className={styles["policy-p"]}>
            Personal data elements collected:
            </p>
            <ul className={styles["list-ul"]}>
              <li>
                <span className={styles["policy-span"]}>Mandatory:</span>  First name, Email ID, Mobile phone (including country code),
              </li>
              <li>
                <span className={styles["policy-span"]}>Optional data elements:</span>  Company Name or Working at.
              </li>
            </ul>
             <div>
            <h4 className={styles["h-4"]}>Purpose of collection:</h4>
            <p className={styles["policy-p"]}>
            Where the collection is for a lawful purpose connected with a function or activity of Data Template or Where the collection is necessary for the purpose listed below, including but not limited to:
            </p>
            <ul className={styles["list-ul"]}>
              <li>
              To carry out various Business Process activities
              </li>
            </ul>
            </div>
            <div>
            <h4 className={styles["h-4"]}>Data Recipients/Accessible to:</h4>
            <p className={styles["policy-p"]}>
            Your Personal Data will be accessible to authorized data recipients for the above processing of Personal Data and may include but not be limited to the following:
            </p>
            <ul className={styles["list-ul"]}>
              <li>
              Internal recipients within Data template affiliates such as Human Resources, Recruitment, Finance, Project Delivery Units, Units/Org Leadership
              </li>
            </ul>
            </div>
            <div>
            <h4 className={styles["h-4"]}>Data Security:</h4>
            <p className={styles["policy-p"]}>
            Data Template complies with reasonable and appropriate security practices and procedures, including administrative, physical, and technical controls to safeguard your Personal Data.
            </p>
            </div>
            <div>
            <h4 className={styles["h-4"]}>Data Protection Officer:</h4>
            <p className={styles["policy-p"]}>
            If you have any queries, comments or requests, please contact our data protection officer via email:<span className={styles.mailto}><a href="mailto:privacy@datatemplate.com">privacy@datatemplate.com</a></span>
            </p>
            </div>
            <div>
            <h4 className={styles["h-4"]}>Disclaimer:</h4>
            <p className={styles["policy-p"]}>
            You acknowledge that any personal information that you share about your dependents or other data subjects is shared by you only after seeking consent from the person to whom the personal data belongs.
            </p>
            </div>


          </div>
       
      </div>
  );
}
