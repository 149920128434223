import React from "react";
import styles from "../../styles/Partner.module.css";
import { Grid } from "@mui/material";
import GoToTop from "../../GoToTop";

const CyberShelter = () => {
  const goTocomp = () => {
    return window.open("https://cybershelter.com/", "__blank");
  };
  return (
    <div className={styles.main}>
      <GoToTop />

      <Grid container className={styles.ElementEnterpriseFirst}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          style={{ paddingTop: "42px" }}>
          <div className={styles["enterprise-block"]}>
            <h1 className={styles["enterprise-h1"]}>CyberShelter</h1>
            <div>
              <h3 className={styles["enterprise-h3"]}></h3>
            </div>
          </div>
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
          <img
            src={process.env.PUBLIC_URL + "/assets/images/cyber/cybershelter.svg"}
            className={styles["enterprise-img"]}
            alt='partner'
          />
          <div className={styles.PHeading}>
            <h3>
              Founded by Cybersecurity stalwarts and passionate investors,
              CyberShelter (CS) is here to make a difference in how
              organizations protect their valuable assets.
            </h3>

            <p>
              {" "}
              In an industry where practical and cost-effective solutions are
              rare, CS brings innovative approaches and robust cybersecurity
              experience at the customer side over the years. We won't propose
              theoretical solutions and half-baked approaches. We ensure that
              you have practically effective remediations to stay relaxed and
              confident about your security posture.
            </p>

            <p>
              We were in your shoes for many years and understood all the pain
              areas bothering you. We design our solutions, execute our
              projects, and support you on an ongoing basis - keeping you in
              mind and the long-term relationship we would like to establish
              with you.
            </p>

            <p>
              The intellectual brains behind CS are CISOs and Cybersecurity
              experts who have worked in major organizations with impactful
              contributions. They have seen every challenge you face and
              searching for solutions and the right service providers. When the
              search could not yield much better results, they decided to enter
              the market with solutions they had identified and developed to
              help their peers.
            </p>
          </div>
        </Grid>
      </Grid>
      <div className={styles.buttonPartner}>
        <button className={styles["btn-view"]} onClick={goTocomp}>
          View More
        </button>
      </div>
    </div>
  );
};

export default CyberShelter;
