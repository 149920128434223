import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "../../styles/Benefits.module.css";
import * as data from "../../../data.js";
import GoToTop from "../../GoToTop";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FormattedMessage } from "react-intl";

export default function Benefits({ currentLanguage }) {
  const dataModules = require.context("../../../data", false, /\.js$/);
  console.log(dataModules, "dataModules");
  const languageData = {};

  dataModules.keys().forEach((key) => {
    const langCode = key.replace(/^\.\/(.+)\.js$/, "$1");
    languageData[langCode] = dataModules(key);
  });
  const [data, setData] = useState(languageData[currentLanguage]);
  gsap.registerPlugin(ScrollTrigger);
  const detailRef = useRef(null);
  const revealsFirstRef = useRef([]);
  revealsFirstRef.current = [];

  useEffect(() => {
    const element = detailRef.current;
    gsap.fromTo(
      element,
      { y: 100, autoAlpha: 0 },
      {
        duration: 3.25,
        y: 5,
        autoAlpha: 4,
        overwrite: "auto",
      }
    );

    revealsFirstRef.current.forEach((el, index) => {
      gsap.set(el, {
        opacity: 0,
        y: 55,
        scale: 0.9,
      });
      gsap.to(el, {
        opacity: 1,
        y: 0,
        scale: 1,
        stagger: 0.2,
        duration: 1.5,
        delay: index * 0.1,
        scrollTrigger: {
          id: `section-${index + 1}`,
          id: "career-part",
          trigger: el,
          start: "top bottom-=20",
          toggleActions: "play none none ",
        },
      });
    });
  }, []);

  const addToFirstRef = (el) => {
    if (el && !revealsFirstRef.current.includes(el)) {
      revealsFirstRef.current.push(el);
    }
  };

  return (
    <div className={styles["benefits_container"]}>
      <GoToTop />
      <div className={styles.benefits}>
        <div ref={detailRef}>
          <div className={styles["benefits-title"]}>
            <h2 className={styles["benefits-H"]}>
              <FormattedMessage id="perks_title" />
            </h2>
          </div>
          <div className={styles["benefits-content"]}>
            <h3>
              <FormattedMessage id="perks_heading" />
            </h3>

            <p>
              <FormattedMessage id="perks_paragraph" />
            </p>
          </div>
        </div>

        <div className={styles["benefits-img"]}>
          <Grid
            id='benefits-part'
            className={styles["benefits-part1"]}
            style={{ padding: '40px', paddingLeft: '46px', paddingBottom: '62px',backgroundColor: "#000" }}
            container
            spacing={2}>
            {data.benefits_imgs.map((item, index) => {
              return (
                <Grid
                  key={index}
                  className={styles["benefits-imgs"]}
                  ref={addToFirstRef}
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}>
                  <div className=''>
                    <div className={styles["img-hover-zoom"]}>
                      <img
                        className={styles.benefitimg}
                        id='imghover'
                        src={process.env.PUBLIC_URL + item.img}
                        alt={item.title}
                        loading='eager|lazy'
                      />
                    </div>
                    <h4 className={styles.benefitstitle}>{item.title}</h4>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
}
