import React from "react";
import styles from "../../styles/Jobdesc.module.css";
import { Link, useLocation } from "react-router-dom";
export default function Jobdesc() {

    const { state } = useLocation();
    function getPosition(string, subString, index) {
        return string.split(subString, index).join(subString).length;
    }
    const expHandler = (content) => {
        let pos1 = getPosition(content, "Years", 1);
        let pos2 = getPosition(content, "-", 1);
        let exp = content.slice(pos2 - 1, pos1 + 5);

        return "Experience " + exp;
    };
    const positionData = state.content.rendered
    function createMarkup() {
        return { __html: positionData };
    }

    return (
            <div className={styles["job-desc"]}>
                    <div className={styles["job-desc-title"]}>
                        <p className={styles["job-desc-p"]}>{state.Location}</p>
                        <h3 className={styles["job-desc-h3"]}>{state.title.rendered}</h3>
                        <p className={styles["job-desc-p1"]}><div dangerouslySetInnerHTML={createMarkup()} /></p>
                       <Link to={{pathname:`/openpositions/jobdesc/${state.title.rendered.replaceAll(' ', '-')}`, state:state.title.rendered}}><div className={styles.applyButton}>
                            <button className={styles["btn-view"]} >Apply</button>
                        </div></Link>
                    </div>


            </div>
    )
}