import React, { useState, useRef } from "react";
import Carousel from "react-material-ui-carousel";
import styles from "../../styles/Home.module.css";
import CustomIndicator from "./CustomIndicator";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import SwiperCore from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FormattedMessage } from "react-intl";

var items = [
  {
    key: 0,
    img: "/assets/images/who we are_mobile.svg",
    video: "/assets/videos/Who we are_3.mp4",
    description: (
      <>
        {window.innerWidth > 768 ? (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="we_work_banner" /> &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="we_work_des" />
                  &nbsp;
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="we_work_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="we_work_des" />
                  &nbsp;
                </span>
              </div>
            </div>
          </>
        )}
      </>
    ),
    shortDec: "Who are we",
  },
  {
    key: 1,
    img: "/assets/images/finance.svg",
    video: "/assets/videos/Fianance_3.mp4",
    description: (
      <>
        {window.innerWidth > 768 ? (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="our_technology_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="our_technology_des" />
                  &nbsp;
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="our_technology_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="our_technology_des" />
                  &nbsp;
                </span>
              </div>
            </div>
          </>
        )}
      </>
    ),
    shortDec: "Finance & Mortgage",
  },
  {
    key: 2,
    img: "/assets/images/TTL.svg",
    video: "/assets/videos/TTL_3.mp4",
    description: (
      <>
        {window.innerWidth > 768 ? (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="we_accelerate_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="we_accelerate_des" />
                  &nbsp;
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="we_accelerate_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="we_accelerate_des" />
                  &nbsp;
                </span>
              </div>
            </div>
          </>
        )}
      </>
    ),
    shortDec: "Travel, Tourism & Logistics",
  },
  {
    key: 3,
    img: "/assets/images/satellite.svg",
    video: "/assets/videos/Satellite and telecom.mp4",
    description: (
      <>
        {window.innerWidth > 768 ? (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="enabling_global_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="enabling_global_des" />
                  &nbsp;
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="enabling_global_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="enabling_global_des" />
                  &nbsp;
                </span>
              </div>
            </div>
          </>
        )}
      </>
    ),
    shortDec: "Satellite & Telecom",
  },
  {
    key: 4,
    img: "/assets/images/Healthcare.svg",
    video: "/assets/videos/Healthcare_3.mp4",
    description: (
      <>
        {window.innerWidth > 768 ? (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="delivering_technology_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="delivering_technology_des" />
                  &nbsp;
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="delivering_technology_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="delivering_technology_des" />
                  &nbsp;
                </span>
              </div>
            </div>
          </>
        )}
      </>
    ),
    shortDec: "Healthcare",
  },
  {
    key: 5,
    img: "/assets/images/Education.svg",
    video: "/assets/videos/Education_3.mp4",
    description: (
      <>
        {window.innerWidth > 768 ? (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="every_day_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="every_day_des" />
                  &nbsp;
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="every_day_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="every_day_des" />
                  &nbsp;
                </span>
              </div>
            </div>
          </>
        )}
      </>
    ),
    shortDec: "Education",
  },
  {
    key: 6,
    img: "/assets/images/Retail.svg",
    video: "/assets/videos/Retail_3.mp4",
    description: (
      <>
        {window.innerWidth > 768 ? (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="more_than_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="more_than_des" />
                  &nbsp;
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="more_than_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="more_than_des" />
                  &nbsp;
                </span>
              </div>
            </div>
          </>
        )}
      </>
    ),
    shortDec: "Retail",
  },
  {
    key: 7,
    img: "/assets/images/Payment.svg",
    video: "/assets/videos/Payment_3.mp4",
    description: (
      <>
        {window.innerWidth > 768 ? (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="payment_systems_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="payment_systems_des" />
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <span className={styles.carou_main_text}>
                <FormattedMessage id="payment_systems_banner" />
                &nbsp;
              </span>
            </div>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div>
                <hr className={styles.carou_hr}></hr>
              </div>
              <div style={{ paddingLeft: "10px" }}>
                <span className={styles.carou_sub_text}>
                  <FormattedMessage id="payment_systems_des" />
                </span>
              </div>
            </div>
          </>
        )}
      </>
    ),
    shortDec: "Finance & Banking",
  },
];

SwiperCore.use([Autoplay, Pagination, Navigation]);

function Carousal(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [autoplayPaused, setAutoplayPaused] = useState(false);
  const [activeShortDec, setActiveShortDec] = useState(items[0].shortDec);
  const [filteredItem, setFilteredItem] = useState([]);
  const [auto, setAuto] = useState();

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex); // Update active index
  };

  React.useEffect(() => {
    if (
      activeIndex === 0 ||
      activeIndex === 1 ||
      activeIndex === 2 ||
      activeIndex === 5
    ) {
      props.setDarkBackground(true);
    } else {
      props.setDarkBackground(false);
    }
  }, [activeIndex]);

  const handleSlideOnChange = (index) => {
    setAuto(false);
    setActiveIndex(index);
    setActiveShortDec(items[index].shortDec);

    let videoEle = document.getElementById("testVideo");
    if (videoEle) {
      videoEle.pause();
      videoEle.src = process.env.PUBLIC_URL + items[index].img;
      videoEle.load();
      videoEle.play();
    }
  };

  const handleNext = () => {
    const swiper = document.querySelector(".mySwiper").swiper;
    swiper.slideNext();
  };

  const handlePrev = () => {
    const swiper = document.querySelector(".mySwiper").swiper;
    swiper.slidePrev();
  };

  const handleMouseEnter = () => {
    if (!autoplayPaused) {
      setAutoplayPaused(true);
      const swiper = document.querySelector(".mySwiper").swiper;
      swiper.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (autoplayPaused) {
      setAutoplayPaused(false);
      const swiper = document.querySelector(".mySwiper").swiper;
      swiper.autoplay.start();
    }
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        height: "100%",
      }}
    >
      {window.innerWidth > 768 ? (
        <Swiper 
          autoHeight={true}
          loop={true}
          speed={2000}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          onSlideChange={(swiper) => handleSlideChange(swiper)}
          className="mySwiper"
        >
          {items.map((item, i) => (
            <SwiperSlide key={i}>
              <Item key={i} item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Swiper
          autoHeight={true}
          loop={true}
          speed={2000}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          onSlideChange={(swiper) => handleSlideChange(swiper)}
          className="mySwiper"
        >
          {items.map((item, i) => (
            <SwiperSlide key={i}>
              <Item
                key={i}
                item={item}
                handlePrev={handlePrev}
                handleNext={handleNext}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {window.innerWidth > 768 ? (
        <>
          {window.innerWidth > 768 && window.innerWidth < 1025 ? (
            <div
              className={styles.custom_indicator}
              style={{ display: "contents" }}
            >
              {/* <p className={styles.shortDecMobile} style={{ position: 'absolute', right: '10%' }}>
                {items[activeIndex].shortDec.toUpperCase()}
              </p> */}
              <div
                className={styles.arrowContainerMobile}
                style={{
                  display: "inline-flex",
                  position: "absolute",
                  left: "90%",
                }}
              >
                <div className={styles.iconBackward} onClick={handlePrev}>
                  <NavigateBeforeIcon fontSize="medium" />
                </div>
                <div className={styles.iconForward} onClick={handleNext}>
                  <NavigateNextIcon fontSize="medium" />
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.custom_indicator}>
              {/* <p className={styles.shortDec}>
                  {items[activeIndex].shortDec.toUpperCase()}
                </p> */}
              <div
                className={styles.arrowContainer}
                style={{
                  display: "inline-flex",
                  position: "absolute",
                  left: "90%",
                }}
              >
                <div className={styles.iconBackward} onClick={handlePrev}>
                  <NavigateBeforeIcon fontSize="large" />
                </div>
                <div className={styles.iconForward} onClick={handleNext}>
                  <NavigateNextIcon fontSize="large" />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={styles.custom_indicator}>
          {/* <p className={styles.shortDecMobile} style={{ position: 'absolute', right: '20%' }}>
              {items[activeIndex].shortDec.toUpperCase()}
            </p> */}
          <div
            className={styles.arrowContainer}
            style={{
              display: "inline-flex",
              position: "absolute",
              left: "90%",
              top: "0%",
            }}
          >
            <div className={styles.iconBackward} onClick={handlePrev}>
              <NavigateBeforeIcon fontSize="large" />
            </div>
            <div className={styles.iconForward} onClick={handleNext}>
              <NavigateNextIcon fontSize="large" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function Item(props) {
  const videoRef = useRef(null);

  return (
    <div
      style={{
        backgroundColor: "transparent",
        paddingTop: "8rem",
      }}
    >
      <div className={styles.container_carousel}>
        <div className={styles.overlay}></div>
        {window.innerWidth > 768 ? (
          <>
            {window.innerWidth > 768 && window.innerWidth < 1025 ? (
              <video
                ref={videoRef}
                // width="120%"
                style={{ height: '100vh' }}
                muted
                autoPlay
                loop
                id="testVideo"
                playsInline
                preload="auto"
                loading="lazy"
              >
                <source
                  src={process.env.PUBLIC_URL + props.item.video}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : (
              <video
                ref={videoRef}
                // width="100%"
                style={{ height: '100vh' }}
                muted
                autoPlay
                loop
                id="testVideo"
                playsInline
                preload="auto"
                loading="lazy"
              >
                <source
                  src={process.env.PUBLIC_URL + props.item.video}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            )}
          </>
        ) : (
          <img width="95%" src={process.env.PUBLIC_URL + props.item.img} />
        )}
        <div className={styles.carou_desc}>{props.item.description}</div>
      </div>
    </div>
  );
}
export default Carousal;
